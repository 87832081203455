import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import Button from "../../general/Button";
import "../../../assets/css/importGuest.css";
import { FileUploader } from "react-drag-drop-files";
import ApiGroups from "../../../services/groupsApi";
import { useAppContext } from "../../../context/state";
import popUpAlert from "../../general/popUpAlert";
import { useNavigate } from "react-router-dom";

const ImportGuests = ({ setOpen, setReFetch }) => {
  const { t } = useTranslation();
  const fileTypes = ["xlsx"];
  const context = useAppContext();
  const [fileImport, setFileImport] = useState({});
  const [errorFile, setErrorFile] = useState(false);

  const handleChange = async (file) => {
    setFileImport(file);
    setErrorFile(false);
  };
  const handleSubmit = async () => {
    if (!fileImport.name) {
      return;
    }
    let payload = {
      invitation_uuid: context.sharedState?.invitation.uuid,
      file: fileImport,
    };

    const response = await ApiGroups.createBatch(
      payload,
      context.sharedState.invitation?.uuid
    );
    if (response === null) {
      setErrorFile(true);
    } else {
      setFileImport({});
      setReFetch(true);
      setOpen(false);
    }
  };

  return (
    <div className="Import-guest-container">
      <p className="import-guest-download-title">
        {t("ImportGuest.downloadTemplates")}
      </p>

      <Button
        icon="fluent:arrow-download-16-filled"
        text={t("ImportGuest.dowloadTemplateButton")}
        onClick={() =>
          (window.location.href =
            "https://kolibird-resources.s3.eu-west-3.amazonaws.com/plantillaInvitados.xlsx")
        }
      />

      <p className="import-guest-steps-title"> {t("ImportGuest.stepsTitle")}</p>
      <div className="import-guest-steps">
        <div className="import-guest-step">
          <span>1</span>
          <p> {t("ImportGuest.stepsOne")}</p>
        </div>
        <div className="import-guest-step">
          <span>2</span>
          <p> {t("ImportGuest.stepsTwo")}</p>
        </div>
        <div className="import-guest-step">
          <span>3</span>
          <p> {t("ImportGuest.stepsThree")}</p>
        </div>
        <div className="import-guest-step">
          <span>4</span>
          <p> {t("ImportGuest.stepsFour")}</p>
        </div>
        <div className="import-guest-step">
          <span>5</span>
          <p> {t("ImportGuest.stepsFive")}</p>
        </div>
      </div>

      <FileUploader
        classes={`import-guest-import-container ${errorFile && "error-file"}`}
        handleChange={handleChange}
        name="file"
        types={fileTypes}
      >
        {errorFile && (
          <span className="error-file-text">{t("ImportGuest.error")}</span>
        )}
        {fileImport.name ? (
          <>
            <p> {fileImport.name}</p>
            {errorFile && (
              <Button
                type="primary"
                className="import-guest-import-button"
                text={t("ImportGuest.importListButton")}
              />
            )}
          </>
        ) : (
          <>
            <Icon icon="akar-icons:arrow-up" />
            <p> {t("ImportGuest.importListTitle")}</p>
            <p> {t("ImportGuest.importListTitleTwo")}</p>
            <Button
              className="import-guest-import-button"
              text={t("ImportGuest.importListButton")}
            />
          </>
        )}
      </FileUploader>
      <div className="import-guest-import-rules">
        <p>&bull; {t("ImportGuest.importListType")}</p>
        <p>&bull; {t("ImportGuest.importListSize")}</p>
        {fileImport.name && <p>&bull; {fileImport.name}</p>}
      </div>
      <Button
        buttonType="submit"
        className="import-guest-add-button"
        type="primary"
        text={t("ImportGuest.AddButton")}
        onClick={handleSubmit}
      />
    </div>
  );
};
export default ImportGuests;
