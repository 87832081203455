import { useState, useEffect } from "react";
import { useTranslation, getI18n } from "react-i18next";
import { useForm } from "react-hook-form";
import { useAppContext } from "../../../context/state";
import popUpAlert from "../../general/popUpAlert";
import groupApi from "../../../services/groupsApi";
import Button from "../../general/Button";
import ToggleButton from "../../general/ToggleButton";
import SaveButtonTwo from "../../general/SaveButtonTwo";
import RadioButton from "../../general/RadioButton";

const OneGuest = ({ onFormAction }) => {
  const [saveError, setSaveError] = useState();
  const [confirmated, setConfirmated] = useState();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const { t } = useTranslation();
  const context = useAppContext();

  const handleChange = (e) => {
    if (e.target.className.includes("know_if_going")) {
      setConfirmated({ know_if_going: false, is_confirmated: false });
    } else if (e.target.className.includes("is_confirmated")) {
      setConfirmated({ is_confirmated: e.target.value, know_if_going: true });
    }
  };

  const onSubmit = async (data) => {
    const verifyConfirmated = data.is_confirmated.length;
    console.log(data);
    let tempData = {
      title: data?.title,
      name: `${data.name} ${data.lastName}`,
      email: data.email,
      phone_number: data.phone_number,

      invitation: context.sharedState.invitation?.id,
    };
    if (verifyConfirmated) {
      tempData = { ...tempData, has_answer: true };
    }
    const response = await groupApi.createGroup(
      tempData,
      context.sharedState.invitation?.uuid
    );

    await submitGuests(response, data);
    onFormAction && onFormAction((data) => !data);
  };

  const submitGuests = async (response, data) => {
    let leader = {
      first_name: data?.name,
      last_name: data?.lastName,
      is_under_age: false,
      is_confirmated: data.is_confirmated || false,
      know_if_going: data.know_if_going || false,
      is_group_leader: true,
      group: response?.id,
      ...confirmated,
    };

    if (data.title?.length) {
      leader = { ...leader, title: data.title };
    }
    const responseGuest = await groupApi.createGuesses(leader, response?.uuid);

    if (typeof responseGuest === "object") {
      setSaveError(false);
      reset();

      document.getElementById("form").reset();
    } else {
      setSaveError(true);
    }
  };

  return (
    <form id="form" onSubmit={handleSubmit(onSubmit)}>
      <div className="one-guest-container">
        <div className="info-field">
          <label className="info-label" htmlFor="event_title">
            {t("addGuest.LabelTitle")}
          </label>
          <div
            id="info-input "
            className={
              !errors?.title && watch().title?.length
                ? " input-ok info-input-container"
                : " info-input-container"
            }
          >
            <select
              placeholder={t("signIn.titlePlaceholder")}
              className={
                errors?.title ? "error input event_title" : "input event_title"
              }
              {...register("title", {})}
            >
              <option value="Sin título">{t("addGuest.select")}</option>
              <option value={"Sin título"}>{t("addGuest.null")}</option>
              <option value={t("addGuest.mrs")}>{t("addGuest.mrs")}</option>
              <option value={t("addGuest.mr")}>{t("addGuest.mr")}</option>
              <option value={t("addGuest.miss")}>{t("addGuest.miss")}</option>
              <option value={t("addGuest.sirs")}>{t("addGuest.sirs")}</option>
              {getI18n().language === "es-ES" && (
                <>
                  <option value={t("addGuest.sirsOf")}>
                    {t("addGuest.sirsOf")}
                  </option>
                  <option value={t("addGuest.don")}>{t("addGuest.don")}</option>
                  <option value={t("addGuest.dona")}>
                    {t("addGuest.dona")}
                  </option>
                </>
              )}
            </select>

            {errors.title && <span className="error-point"></span>}
            {errors.title && (
              <span className="error-text">{t("general.requiredField")}</span>
            )}
          </div>
        </div>
        <div className="info-field">
          <label className="info-label" htmlFor="name">
            {t("addGuest.LabelName")}
          </label>
          <div
            id="info-input"
            className={
              !errors?.name && watch().name?.length
                ? " input-ok info-input-container"
                : "info-input-container"
            }
          >
            <input
              type="text"
              placeholder={t("addGuest.namePlaceholder")}
              {...register("name", {
                required: true,
                min: 2,
              })}
              onInput={(e) => {
                reset({
                  value: e.target.value,
                  keepDirty: true,
                  keepError: true,
                  keepIsValid: true,
                });
              }}
              className={errors?.name ? "error input" : "input"}
              id="name"
            />

            {errors.name && <span className="error-point"></span>}
            {errors.name && (
              <span className="error-text">{t("general.requiredField")}</span>
            )}
          </div>
        </div>
        <div className="info-field">
          <label className="info-label" htmlFor="LastName">
            {t("addGuest.LabelLastName")}
          </label>
          <div
            id="info-input"
            className={
              !errors?.lastName && watch().lastName?.length
                ? " input-ok info-input-container"
                : "info-input-container"
            }
          >
            <input
              type="text"
              placeholder={t("addGuest.lastNamePlaceholder")}
              {...register("lastName", {
                min: 2,
                required: true,
              })}
              onInput={(e) => {
                reset({
                  value: e.target.value,
                });
              }}
              className={errors?.lastName ? "error input" : "input"}
              id="lastName"
            />

            {errors.lastName && <span className="error-point"></span>}
            {errors.lastName && (
              <span className="error-text">{t("general.requiredField")}</span>
            )}
          </div>
        </div>
        <div className="info-field">
          <label className="info-label" htmlFor="email">
            {t("addGuest.LabelEmail")}
          </label>
          <div
            id="info-input"
            className={
              !errors?.email && watch().email?.length
                ? " input-ok info-input-container"
                : "info-input-container"
            }
          >
            <input
              type="text"
              placeholder={t("addGuest.emailPlaceholder")}
              {...register("email", {
                required: true,
                pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
              })}
              onInput={(e) => {
                reset({
                  value: e.target.value,
                });
              }}
              id="email"
              className={errors?.email ? "error input" : "input"}
            />

            {errors.email && <span className="error-point"></span>}
            {errors.email && (
              <span className="error-text">{t("signIn.emailError")}</span>
            )}
          </div>
        </div>
        <div className="info-field">
          <label className="info-label" htmlFor="phone">
            {t("addGuest.LabelPhone")}
          </label>
          <div
            id="info-input"
            className={
              !errors?.phone_number && watch().phone_number?.length
                ? " input-ok info-input-container"
                : "  info-input-container"
            }
          >
            <input
              {...register("phone_number", {
                pattern: /^[0-9]+$/i,
                minLength: 8,
                maxLength: 11,
              })}
              onInput={(e) => {
                reset({
                  value: e.target.value,
                });
              }}
              maxLength="11"
              type="tel"
              id="phone_number"
              className={errors?.phone_number ? "error input" : "input"}
              placeholder={t("addGuest.phonePlaceholder")}
            />

            {errors?.phone_number && <span className="error-point"></span>}
            {errors?.phone_number && (
              <span className="error-text">{t("general.requiredField")}</span>
            )}
          </div>
        </div>
        <div className="oneGuest-confirmated">
          <label className="info-label" htmlFor="phone">
            {t("addGuest.attending")}
          </label>
          <div className="oneGuest-confirmated-inputs">
            {}{" "}
            <div>
              <RadioButton
                onClick={(e) => handleChange(e)}
                id="attend"
                value={true}
                className="is_confirmated"
                required={{
                  ...register("is_confirmated", {
                    required: true,
                  }),
                }}
              />

              <label htmlFor={`attend`}>{t("addGuest.attendingTrue")}</label>
            </div>{" "}
            <div>
              <RadioButton
                onClick={(e) => handleChange(e)}
                id="nullAttend"
                // value="null"
                className="is_confirmated know_if_going"
                defaultChecked={true}
                required={{
                  ...register("is_confirmated", {
                    required: true,
                  }),
                }}
              />
              <label htmlFor={`nullAttend`}>
                {t("addGuest.attendingNull")}
              </label>
            </div>{" "}
            <div>
              <RadioButton
                onClick={(e) => handleChange(e)}
                id="noAttend"
                value={false}
                className="is_confirmated"
                required={{
                  ...register("is_confirmated", {
                    required: true,
                  }),
                }}
              />
              <label htmlFor={`noAttend`}>{t("addGuest.attendingFalse")}</label>
            </div>
          </div>
        </div>
      </div>
      <div className="submit-list-group-button">
        <SaveButtonTwo saveError={isValid ? saveError : true} />
      </div>
    </form>
  );
};
export default OneGuest;
