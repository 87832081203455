import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../general/Button";
import CheckBoxButton from "../../general/CheckBoxButton";
import { useAppContext } from "../../../context/state";
import Invitation from "../invitation";
import imageSubmit from "../../../assets/img/draws/submitted.svg";
import { useForm } from "react-hook-form";

const SubmitInvitations = ({
  setModalSubmit,
  submit,
  selectGroups,
  submitted,
}) => {
  const { t } = useTranslation();
  const context = useAppContext();
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({ mode: "onChange" });
  const newGroups = selectGroups.filter((group) => group?.is_sent == false);
  const reSendGroups = selectGroups.filter(
    (group) =>
      (group?.is_sent == true && group?.has_answer === false) ||
      group?.has_sent_error
  );
  const noSendGroups =
    selectGroups.length - newGroups.length - reSendGroups.length;

  return (
    <div className="submit-envelope-container">
      {!submitted ? (
        <form
          onSubmit={handleSubmit(submit)}
          className="submit-envelope-form-container"
        >
          <div>
            <h3>{t("guestListBoard.submitTitle")}</h3>
            <p className="submit-envelope-ask">
              {t("guestListBoard.submitAsk")}
            </p>
            {newGroups.length ? (
              <p className="submit-envelope-ask">
                {newGroups.length}{" "}
                {newGroups.length > 1
                  ? t("guestListBoard.contactsSelected")
                  : t("guestListBoard.contactSelected")}
              </p>
            ) : null}
            {reSendGroups.length ? (
              <p className="submit-envelope-ask">
                {reSendGroups.length}{" "}
                {reSendGroups.length > 1
                  ? t("guestListBoard.sendContactsSelected")
                  : t("guestListBoard.sendContactSelected")}
              </p>
            ) : null}
            {noSendGroups > 0 ? (
              <p className="submit-envelope-ask">
                {noSendGroups}{" "}
                {noSendGroups > 1
                  ? t("guestListBoard.noSendContactsSelected")
                  : t("guestListBoard.noSendContactSelected")}
              </p>
            ) : null}
          </div>

          <div className="img-submit-envelope-container">
            <Invitation
              configuration={
                context.sharedState.invitation.envelope_configuration
              }
            />
          </div>
          <div className="submit-checkBox info-input-container">
            <CheckBoxButton
              validations={{
                ...register("sendInvitation", {
                  required: true,
                }),
              }}
              id="checkSendAll"
            />

            <label htmlFor="checkSendAll">
              {t("guestListBoard.sendAllLabel")}
            </label>
            {errors?.sendInvitation && <span className="error-point"></span>}
            {errors?.sendInvitation && (
              <span className="error-text">{t("general.requiredField")}</span>
            )}
          </div>
          <div className="submit-buttons-container">
            <Button
              buttonType="button"
              onClick={() => setModalSubmit(false)}
              text={t("guestListBoard.cancelSubmitButton")}
            />

            <Button
              buttonType="submmit"
              text={t("guestListBoard.SubmitButton")}
              type="primary"
            />
          </div>
        </form>
      ) : (
        <>
          <img className="submitted-img" src={imageSubmit} />
          <h2 className="submitted-title">{t("guestListBoard.submittedH2")}</h2>
          <p className="submitted-content">
            {t("guestListBoard.submittedContent")}
          </p>
        </>
      )}
    </div>
  );
};
export default SubmitInvitations;
