import axios from "axios";
import Api from "./usersApi";
import { generateApiToken } from "./utils";

class ApiGroups {
  static getConfig() {
    return {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    };
  }

  static getConfigNoAuth() {
    return {
      headers: {
        token: generateApiToken(),
      },
    };
  }

  static async getBatch(payload) {
    const url = `/invitations/export-guests/`;
    let config = ApiGroups.getConfig();
    config.headers = {
      ...config.headers,
      ["Content-Type"]: "multipart/form-data",
    };
    let group;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        payload,
        config
      );
      group = response.data;
    } catch (error) {
      await Api.refreshToken();
      return error.response.data.error;
    }
    return group;
  }
  static async createBatch(payload) {
    const url = `/invitations/groups/create-batch/`;
    let config = ApiGroups.getConfig();
    config.headers = {
      ...config.headers,
      ["Content-Type"]: "multipart/form-data",
    };

    let group;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        payload,
        config
      );
      return response.data;
    } catch (error) {
      await Api.refreshToken();
      return null;
    }
  }
  static async getGroups(id, filter = "") {
    const url = `/invitations/${id}/groups/${filter}`;
    const config = ApiGroups.getConfig();
    let group;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        config
      );
      group = response.data;
    } catch (error) {
      await Api.refreshToken();
      return error.response.data.error;
    }
    return group;
  }
  static async getGroupsQuery(query) {
    let url = query;
    if (query.includes("http://web/")) {
      url = query.replace("http://web/", `${process.env.REACT_APP_URL}`);
    }
    const config = ApiGroups.getConfig();
    let group;
    try {
      const response = await axios.get(url, config);
      group = response.data;
    } catch (error) {
      await Api.refreshToken();
      return error.response.data.error;
    }
    return group;
  }
  static async getGroup(invitationId, id) {
    const url = `/invitations/${invitationId}/groups/${id}/response/`;

    const config = ApiGroups.getConfigNoAuth();

    let group;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        config
      );
      group = response.data;
    } catch (error) {
      return error.response.data.error;
    }
    return group;
  }
  static async createGroup(payload, id) {
    const url = `/invitations/${id}/groups/`;
    const config = ApiGroups.getConfig();
    let group;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        payload,
        config
      );
      group = response.data;
    } catch (error) {
      await Api.refreshToken();
      return error.response.data.error;
    }
    return group;
  }
  static async changeGroup(payload, id, idGroup) {
    const url = `/invitations/${id}/groups/${idGroup}/`;
    const config = ApiGroups.getConfig();
    let group;
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        payload,
        config
      );
      group = response.data;
    } catch (error) {
      await Api.refreshToken();
      return error.response.data.error;
    }
    return group;
  }
  static async changeGroupOpen(id, idGroup) {
    const url = `/invitations/${id}/groups/${idGroup}/response/`;

    const config = ApiGroups.getConfigNoAuth();

    const payload = { is_opened: true };
    let group;
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        payload,
        config
      );
      group = response.data;
    } catch (error) {
      return error.response.data.error;
    }
    return group;
  }
  static async changeGroupAnswered(id, idGroup) {
    const url = `/invitations/${id}/groups/${idGroup}/response/`;

    const config = ApiGroups.getConfigNoAuth();

    const payload = { has_answer: true };
    let group;
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        payload,
        config
      );
      group = response.data;
    } catch (error) {
      return error.response.data.error;
    }
    return group;
  }
  static async deleteGroups(idInvitation, idGrup) {
    const url = `/invitations/${idInvitation}/groups/${idGrup}/`;
    const config = ApiGroups.getConfig();
    let group;
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        config
      );
      group = response.data;
    } catch (error) {
      await Api.refreshToken();
      return error.response.data.error;
    }
    return group;
  }
  static async createGuesses(payload, id) {
    const url = `/invitations/groups/${id}/guests/`;
    const config = ApiGroups.getConfig();
    let guesses;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        payload,
        config
      );
      guesses = response.data;
    } catch (error) {
      await Api.refreshToken();
      return error.response.data.error;
    }
    return guesses;
  }
  static async changeGueests(payload, idgroup, idGuest) {
    const url = `/invitations/groups/${idgroup}/guests/${idGuest}/`;
    const config = ApiGroups.getConfig();
    let guesses;
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        payload,
        config
      );
      guesses = response.data;
    } catch (error) {
      await Api.refreshToken();
      return error.response.data.error;
    }
    return guesses;
  }
  static async deleteGuests(idGroups, idGuest) {
    const url = `/invitations/groups/${idGroups}/guests/${idGuest}/`;
    const config = ApiGroups.getConfig();
    let group;
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        config
      );
      group = response;
    } catch (error) {
      await Api.refreshToken();
      return error.response.data.error;
    }
    return group;
  }
  static async getGuests(id) {
    const url = `/invitations/groups/${id}/guests/`;
    const config = ApiGroups.getConfig();
    let group;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        config
      );
      group = response;
    } catch (error) {
      await Api.refreshToken();
      return error.response.data.error;
    }
    return group;
  }
  static async getAllGuests(id) {
    const url = `/invitations/${id}/guests/`;
    const config = ApiGroups.getConfig();
    let guest;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        config
      );
      guest = response;
    } catch (error) {
      await Api.refreshToken();
      return error.response.data.error;
    }
    return guest;
  }
}
export default ApiGroups;
