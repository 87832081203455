import { useState, useEffect } from "react";
import StartPoll from "./startPoll";
import PollTemplates from "./pollTemplates";
import PollCreation from "./pollCreation";
import "../../assets/css/poll.css";
import { useAppContext } from "../../context/state";
import EditButton from "../general/editButton";
import { useTranslation } from "react-i18next";
import popUpAlert from "../general/popUpAlert";
import SaveButton from "../general/saveButton";
import Datalayer from "../../services/datalayer";

function PollBoard() {
  const [step, setStep] = useState(2);
  const context = useAppContext();
  const updated = new Date(context.sharedState?.invitation?.updated);
  const { t } = useTranslation();

  const handleStep = (currentStep) => setStep(currentStep);

  useEffect(() => {
    Datalayer.push("poll_step");
  }, []);

  return (
    <div className="polls-container">
      <div className="save-button-poll">
        <SaveButton saveError={false} />
      </div>
      <div className="poll-header">
        <h1>
          {t("poll.questionaire")}: {context.sharedState.invitation?.event_name}
        </h1>
      </div>
      {step === 0 && <StartPoll handleStep={handleStep} />}
      {step === 1 && <PollTemplates />}
      {step === 2 && <PollCreation />}
    </div>
  );
}
export default PollBoard;
