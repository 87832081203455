import { useState, useEffect } from "react";
import { useTranslation, getI18n } from "react-i18next";
import { useForm } from "react-hook-form";
import { useAppContext } from "../../../context/state";
import groupApi from "../../../services/groupsApi";
import Button from "../../general/Button";
import EditButton from "../../general/editButton";
import CheckBoxButton from "../../general/CheckBoxButton";
import { Icon } from "@iconify/react";
import PopUpAlert from "../../general/popUpAlert";
import ApiGroups from "../../../services/groupsApi";
import popUpAlert from "../../general/popUpAlert";
import ToggleButton from "../../general/ToggleButton";
import SaveButton from "../../general/saveButton";
import SaveButtonTwo from "../../general/SaveButtonTwo";
import RadioButton from "../../general/RadioButton";

const AddGroup = ({
  group,
  setOnOpen,
  setInvitationSaveOutside,
  onFormAction,
}) => {
  const [guests, setGuests] = useState(
    group?.guests
      ? group?.guests
      : [{ title: "", first_name: "", last_name: "" }]
  );
  const [invitationSave, setInvitationSave] = useState();
  const [saveError, setSaveError] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const { t } = useTranslation();
  const context = useAppContext();
  const {
    register,
    handleSubmit,
    resetField,
    formState,
    reset,
    watch,
    unregister,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  useEffect(() => {
    if (group) {
      setGuests(group.guests);
    }
  }, [group]);

  const handleChange = (e, index) => {
    let temp = [...guests];

    if (
      e.target.className.includes("guestUndefined") &&
      e.target.checked == true
    ) {
      temp[index] = {
        ...temp[index],
        title: "-",
        first_name: "Desconocido",
        last_name: "-",
      };
    } else if (
      e.target.className.includes("guestUndefined") &&
      e.target.checked == false
    ) {
      temp[index] = {
        ...guests[index],
        title: "",
        first_name: "",
        last_name: "",
      };
    } else if (e.target.className.includes("younger")) {
      temp[index] = {
        younger: e.target.checked,
        ...guests[index],
      };
    } else if (e.target.className.includes("know_if_going")) {
      temp[index] = {
        ...guests[index],
        know_if_going: false,
        is_confirmated: false,
      };
    } else if (e.target.className.includes("is_confirmated")) {
      temp[index] = {
        ...guests[index],
        is_confirmated: e.target.value,
        know_if_going: true,
      };
    } else {
      temp[index][e.target.id] = e.target.value;
    }

    setGuests([...temp]);
  };

  const addGuest = () => {
    const mokGuest = {
      title: "",
      first_name: "",
      last_name: "",
    };

    setGuests([...guests, mokGuest]);
  };

  const removeGuest = (index, guest, group) => () => {
    if (index > 0 && group?.guests?.includes(guest)) {
      unregister();
      setGuests(guests.filter((item) => item !== guests[index]));
      ApiGroups.deleteGuests(group.uuid, guest?.uuid);
    }
    if (index > 0) {
      unregister();
      setGuests(guests.filter((item) => item !== guests[index]));
    }
  };

  const onSubmit = async (data) => {
    const verifyConfirmated = guests.find((guest) => guest.know_if_going);

    let tempData = {
      name: data?.name,
      email: data.email,
      phone_number: data.phone_number,
      invitation: context.sharedState.invitation?.id,
    };

    if (verifyConfirmated) {
      tempData = { ...tempData, has_answer: true };
    }

    let response;
    if (group) {
      response = await groupApi.changeGroup(
        tempData,
        context.sharedState.invitation?.uuid,
        group?.uuid
      );
    } else {
      response = await groupApi.createGroup(
        tempData,
        context.sharedState.invitation?.uuid
      );
    }

    await submitGuests(response);
    onFormAction && onFormAction((data) => !data);
  };

  const submitGuests = async (response) => {
    const responseGuest = await Promise.all(
      guests.map((guest, index) => {
        const isGroupLeader = index === 0;

        let leader = {
          uuid: guest.uuid,
          title: guest.title,
          first_name: guest.first_name,
          last_name: guest.last_name,
          is_under_age: guest.younger,
          is_confirmated: guest.is_confirmated,
          know_if_going: guest.know_if_going,
          is_group_leader: isGroupLeader,
          group: response?.id,
        };

        // if (guest.is_confirmated !== "")
        //   leader.is_confirmated = guest?.is_confirmated;

        if (group && guest.uuid == undefined) {
          return groupApi.createGuesses(leader, response?.uuid);
        } else if (group) {
          return groupApi.changeGueests(leader, response?.uuid, leader.uuid);
        } else {
          return groupApi.createGuesses(leader, response?.uuid);
        }
      })
    );

    if (group && typeof responseGuest == "object") {
      setOnOpen(false);
      setSaveError(false);
    } else if (typeof responseGuest == "object") {
      setGuests([{ title: "", first_name: "", last_name: "" }]);

      setSaveError(false);
      reset();
      document.getElementById("form").reset();
    } else {
      setSaveError(true);
    }
  };

  return (
    <>
      <form id="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="group-container">
          <div className="info-field">
            <label className="info-label" htmlFor="name">
              {t("addGuest.LabelNameGroup")}
            </label>
            <div
              id="info-input"
              className={
                !errors?.name && watch().name?.length
                  ? " input-ok info-input-container"
                  : " info-input-container"
              }
            >
              <input
                type="text"
                placeholder={t("addGuest.namePlaceholder")}
                {...register("name", {
                  required: true,
                })}
                onInput={(e) => {
                  reset({
                    value: e.target.value,
                  });
                }}
                className={errors?.name ? "error input" : "input"}
                id="name"
                defaultValue={group?.name}
              />

              {errors.name && <span className="error-point"></span>}
              {errors.name && (
                <span className="error-text">{t("general.requiredField")}</span>
              )}
            </div>
          </div>

          <div className="info-field">
            <label className="info-label" htmlFor="email">
              {t("addGuest.LabelEmail")}
            </label>
            <div
              id="info-input"
              className={
                !errors?.email && watch().email?.length
                  ? " input-ok info-input-container"
                  : "  info-input-container"
              }
            >
              <input
                type="text"
                placeholder={t("addGuest.emailPlaceholder")}
                {...register("email", {
                  required: true,
                  pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                })}
                onInput={(e) => {
                  reset({
                    value: e.target.value,
                  });
                }}
                id="email"
                className={errors?.email ? "error input" : "input"}
                defaultValue={group?.email}
              />

              {errors.email && <span className="error-point"></span>}
              {errors.email && (
                <span className="error-text">{t("signIn.emailError")}</span>
              )}
            </div>
          </div>
          <div className="info-field">
            <label className="info-label" htmlFor="phone">
              {t("addGuest.LabelPhone")}
            </label>
            <div
              id="info-input"
              className={
                !errors?.phone_number && watch().phone_number?.length > 0
                  ? " input-ok info-input-container"
                  : "info-input-container"
              }
            >
              <input
                {...register("phone_number", {
                  pattern: /^[0-9]+$/i,
                  minLength: 8,
                  maxLength: 11,
                })}
                onInput={(e) => {
                  reset({
                    value: e.target.value,
                  });
                }}
                maxLength="11"
                type="tel"
                id="phone_number"
                className={errors?.phone_number ? "error input" : "input"}
                placeholder={t("addGuest.phonePlaceholder")}
                defaultValue={group?.phone_number}
              />

              {errors?.phone_number && <span className="error-point"></span>}
              {errors?.phone_number && (
                <span className="error-text">{t("general.requiredField")}</span>
              )}
            </div>
          </div>
        </div>
        <div className="gues-groups-container">
          <div>
            {guests?.map((item, index) => {
              return (
                <div className="gues-groups" key={index}>
                  <div className="gues-groups-inputs" disabled>
                    <div className="delete">
                      {index > 0 && (
                        <Icon
                          icon="heroicons:trash-solid"
                          onClick={removeGuest(index, item, group)}
                        />
                      )}
                    </div>
                    <div className="info-field">
                      <label className="info-label" htmlFor="event_title">
                        {t("addGuest.LabelTitle")}
                      </label>
                      <div
                        id="info-input "
                        className={
                          !errors[`guest${index}`]?.title &&
                          watch()[`guest${index}`]?.title?.length > 0
                            ? " input-ok info-input-container"
                            : " info-input-container"
                        }
                      >
                        <select
                          autoFocus={guests.length == index + 1 && index > 0}
                          placeholder={t("signIn.titlePlaceholder")}
                          id="title"
                          className={
                            errors[`guest${index}`]?.title
                              ? "error input event_title"
                              : "input event_title"
                          }
                          onInput={(e) => handleChange(e, index)}
                          value={item.title}
                        >
                          <option value="Sin título">
                            {t("addGuest.select")}
                          </option>
                          <option value="Sin título">
                            {t("addGuest.null")}
                          </option>
                          <option value={t("addGuest.mrs")}>
                            {t("addGuest.mrs")}
                          </option>
                          <option value={t("addGuest.mr")}>
                            {t("addGuest.mr")}
                          </option>
                          <option value={t("addGuest.miss")}>
                            {t("addGuest.miss")}
                          </option>
                          <option value={t("addGuest.sirs")}>
                            {t("addGuest.sirs")}
                          </option>

                          {getI18n().language === "es-ES" && (
                            <>
                              <option value={t("addGuest.sirsOf")}>
                                {t("addGuest.sirsOf")}
                              </option>
                              <option value={t("addGuest.don")}>
                                {t("addGuest.don")}
                              </option>
                              <option value={t("addGuest.dona")}>
                                {t("addGuest.dona")}
                              </option>
                            </>
                          )}
                        </select>

                        {errors[`guest${index}`]?.title && (
                          <span className="error-point"></span>
                        )}
                        {errors[`guest${index}`]?.title && (
                          <span className="error-text">
                            {t("general.requiredField")}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="info-field">
                      <label className="info-label" htmlFor="first_name">
                        {t("addGuest.LabelName")}
                      </label>
                      <div
                        id="info-input"
                        className={
                          !errors[`guest${index}`]?.first_name &&
                          watch()[`guest${index}`]?.first_name?.length > 0
                            ? " input-ok info-input-container"
                            : "info-input-container"
                        }
                      >
                        <input
                          type="text"
                          placeholder={t("addGuest.namePlaceholder")}
                          {...register(`guest${index}.first_name`, {
                            required:
                              item.first_name?.length > 1 ? false : true,
                            disabled: item.first_name == "X" ? true : false,
                          })}
                          className={
                            errors[`guest${index}`]?.first_name
                              ? "error input"
                              : "input"
                          }
                          id="first_name"
                          onInput={(e) => handleChange(e, index)}
                          value={item?.first_name}
                        />

                        {errors[`guest${index}`]?.first_name && (
                          <span className="error-point"></span>
                        )}
                        {errors[`guest${index}`]?.first_name && (
                          <span className="error-text">
                            {t("general.requiredField")}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="info-field">
                      <label className="info-label" htmlFor="last_name">
                        {t("addGuest.LabelLastName")}
                      </label>
                      <div
                        id="info-input"
                        className={
                          !errors[`guest${index}`]?.last_name &&
                          watch()[`guest${index}`]?.last_name?.length > 0
                            ? " input-ok info-input-container"
                            : "info-input-container"
                        }
                      >
                        <input
                          type="text"
                          placeholder={t("addGuest.lastNamePlaceholder")}
                          {...register(`guest${index}.last_name`, {
                            required: item.last_name?.length > 1 ? false : true,

                            disabled: item.last_name == "X" ? true : false,
                          })}
                          className={
                            errors[`guest${index}`]?.last_name
                              ? "error input"
                              : "input"
                          }
                          id="last_name"
                          onInput={(e) => handleChange(e, index)}
                          value={item.last_name}
                        />

                        {errors[`guest${index}`]?.last_name && (
                          <span className="error-point"></span>
                        )}
                        {errors[`guest${index}`]?.last_name && (
                          <span className="error-text">
                            {t("general.requiredField")}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="gues-group-check">
                    {index !== 0 && (
                      <>
                        <div onInput={(e) => handleChange(e, index)}>
                          <CheckBoxButton
                            checked={item?.title == "-" ? true : false}
                            className="guestUndefined"
                            id={`guestUndefined${index}`}
                          />
                          <label htmlFor={`guestUndefined${index}`}>
                            {t("addGuest.unknow")}
                          </label>
                        </div>
                        <div onInput={(e) => handleChange(e, index)}>
                          <CheckBoxButton
                            checked={item?.is_under_age}
                            className="is_under_age"
                            id={`younger${index}`}
                          />
                          <label htmlFor={`younger${index}`}>
                            {t("addGuest.kid")}
                          </label>
                        </div>
                      </>
                    )}
                    <div>
                      <div className="oneGuest-confirmated">
                        <label className="info-label" htmlFor="phone">
                          {t("addGuest.attending")}
                        </label>
                        <div className="oneGuest-confirmated-inputs">
                          <div>
                            <RadioButton
                              onClick={(e) => handleChange(e, index)}
                              onClicks={(e) => handleChange(e, index)}
                              id={`attend${index}`}
                              name={`attend${index}`}
                              value={true}
                              className="is_confirmated"
                              defaultChecked={
                                item?.is_confirmated && item.know_if_going
                                  ? true
                                  : false
                              }
                              required={{
                                ...register(`guest${index}.is_confirmated`, {
                                  required: true,
                                }),
                              }}
                            />

                            <label htmlFor={`attend`}>
                              {t("addGuest.attendingTrue")}
                            </label>
                          </div>
                          <div>
                            <RadioButton
                              onClick={(e) => handleChange(e, index)}
                              onClicks={(e) => handleChange(e, index)}
                              id={`nullAttend${index}`}
                              name={`attend${index}`}
                              // value={false}
                              className="is_confirmated know_if_going"
                              defaultChecked={
                                !item?.know_if_going ? true : false
                              }
                              required={{
                                ...register(`guest${index}.is_confirmated`, {
                                  required: true,
                                }),
                              }}
                            />
                            <label htmlFor={`nullAttend${index}`}>
                              {t("addGuest.attendingNull")}
                            </label>
                          </div>
                          <div>
                            <RadioButton
                              onClick={(e) => handleChange(e, index)}
                              id={`noAttend${index}`}
                              name={`attend${index}`}
                              defaultChecked={
                                !item?.is_confirmated && item.know_if_going
                                  ? true
                                  : false
                              }
                              value={false}
                              className="is_confirmated"
                              required={{
                                ...register(`guest${index}.is_confirmated`, {
                                  required: true,
                                }),
                              }}
                            />
                            <label htmlFor={`noAttend${index}`}>
                              {t("addGuest.attendingFalse")}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <EditButton
            type="button"
            onClick={addGuest}
            text={t("addGuest.addGuestGroup")}
            icon="fluent:add-16-filled"
            className="add-guest-grpup-button"
            color="#A04DB3"
          />
        </div>
        <div className="submit-list-group-button">
          <SaveButtonTwo saveError={isValid ? saveError : true} />
        </div>
      </form>
    </>
  );
};
export default AddGroup;
